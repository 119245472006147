<template lang="">
  <div>
    <div class="order-xl-1">
      <div class="card custom-card">
        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ชื่อผู้ใช้</label>
                    <div class="col-sm-8">
                      <div class="form-control">{{user.name}}</div>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">รหัสผ่านเดิม</label>
                    <div class="col-sm-8">
                      <base-input type="password" name="old_password" :rules="{required: true, min: 8}" placeholder="รหัสผ่าน"
                                  v-model="item.oldPassword">
                      </base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">รหัสผ่าน</label>
                    <div class="col-sm-8">
                      <base-input type="password" name="password" :rules="{required: true, min: 8}" placeholder="รหัสผ่าน"
                                  v-model="item.password">
                      </base-input>
                    </div>
                  </div>
                  <div class="mb-4 row">
                    <label class="col-sm-4 col-form-label label_algin_right">ยืนยันรหัสผ่าน</label>
                    <div class="col-sm-8">
                      <base-input type="password" name="confirm_password" :rules="{required: true, min: 8}" placeholder="รหัสผ่าน"
                                  v-model="item.confirmPassword">
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="my-4">
                </div>
              </div>
              <div class="float-right d-flex">
                <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> ยกเลิก</b-button>
                <b-button type="submit" variant="primary" class="style_btn"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SessionStorageUtils from '../../../util/sessionStorageUtils';
export default {
  name: 'customer-view-page-customer-edit',
  data () {
    return {
      user: {},
      item: {}
    }
  },
  methods: {
    async editHandler () {
      if(this.item.password!=this.item.confirmPassword){
        this.AlertUtils.alert('warning', "รหัสผ่านไม่ตรงกัน");
        return;
      }
      const result = await this.HttpServices.putData(`/ab_users/changePassword/${this.user.id}`, this.item);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/");
    },
  },
  async mounted() {
    const user = await SessionStorageUtils.decodeUser();
    this.user = user;
  }
}
</script>
<style lang="">

</style>
